// extracted by mini-css-extract-plugin
export var saasBusinessesSection = "s_b3";
export var saasClientQuotesSection = "s_ch";
export var saasDevExpertiseSection = "s_cf";
export var saasDiscoverWorksSection = "s_cb";
export var saasDomainExpertiseSection = "s_b5";
export var saasLineSection = "s_b4";
export var saasProcessSection = "s_b6";
export var saasRequirementSoftwareSection = "s_b9";
export var saasServicesSection = "s_b1";
export var saasSolutionSection = "s_cg";
export var saasSolutionsSection = "s_b2";
export var saasSuccessfulSection = "s_cc";
export var saasTechStackSection = "s_b7";
export var saasTechStackSection__callToAction = "s_b8";
export var saasWhyShouldSection = "s_cd";