import { useAddClassNameWhenVisible } from '~/hooks'
import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { DataContentProps } from '~/types/helper'
import { RequirementsSoftwareMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesRequirementsSoftware.module.scss'

const ServicesRequirementsSoftware = ({
  dataContent,
}: DataContentProps<RequirementsSoftwareMock>) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(({ icon, title, list }) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName:
            containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
          loadedClassName:
            containerStylesSpriteIcons.spriteIconsWrapper__sprite,
        })

        return (
          <div ref={ref} key={title} className="col-lg-4 col-md-6 col-sm-12">
            <div className={containerStyles.servicesRequirementsSection__card}>
              {icon && (
                <div
                  className={cx(
                    containerStyles.servicesRequirementsSection__icon,
                    containerStylesSpriteIcons.spriteIconsWrapper,
                    containerStylesSpriteIcons[`spriteIconsWrapper__${icon}`],
                  )}
                />
              )}
              <h3
                className={containerStyles.servicesRequirementsSection__title}
              >
                {title}
              </h3>
              {list && (
                <ul
                  className={containerStyles.servicesRequirementsSection__list}
                >
                  {list?.map(({ item }) => (
                    <li
                      key={String(item)}
                      className={
                        containerStyles.servicesRequirementsSection__listItem
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default ServicesRequirementsSoftware
